<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
			<div class="card">
				<div class="card-body">
					<div class="row">
						<div class="col-md-12 text-right">
							<a-button class="btn btn-info" icon="plus" @click="onAddRecord" v-if="userData.role == 'super-admin' || userData.role == 'admin'">{{ $t('general.add') }}</a-button>
							<a-button class="btn ml5" icon="download" @click="onDownloadReport">Descargar reporte</a-button>
							<download-csv ref="downloadReportButton" :data="json_data" v-if="userData.role == 'super-admin' || userData.role == 'admin'" v-show="false">
								Descargar reporte
							</download-csv>
							<a-button class="btn btn-success ml5" icon="reload" @click="onReload" />
						</div>
					</div>
					<div class="row pt15">
						<div class="col-md-12">
							<a-table :columns="columns" :dataSource="projectsList" :pagination="{ pageSize: 100 }">
								<div slot="code" slot-scope="record">
									{{ record.code.toUpperCase() }}
								</div>
								<div slot="projectName" slot-scope="record" class="text-left">
									{{ record.name }}
								</div>
								<div slot="full_name" slot-scope="record" class="text-left">
									{{ record.full_name }}
								</div>
								<div slot="profit" slot-scope="record">
									<div v-if="userData.role == 'super-admin' && record.profit > 0">
										{{ numeral(record.profit * 100).format('0.00') }}%
									</div>
									<div v-else>-</div>
								</div>
								<div slot="budget" slot-scope="record">
									<div v-if="spendBudget(record.id) > 100">
										<a-progress type="line" :showInfo="spendBudget(record.id) <= 100" :status="spendBudget(record.id) <= 100 ? 'active' : 'exception'"
													:percent="spendBudget(record.id)" style="width: 70%;" />
										<small class="text-muted">{{ spendBudget(record.id) }}%</small>
									</div>
									<div v-else>
										<a-progress type="line" :showInfo="spendBudget(record.id) <= 100" :status="spendBudget(record.id) <= 100 ? 'active' : 'exception'"
													:percent="spendBudget(record.id)" />
									</div>
								</div>
								<div slot="status" slot-scope="record">
									<div class="text-center">
										<a-tag color="blue" v-if="record.status == 'in_planning'"><b>- En planeación -</b></a-tag>
										<a-tag color="green" v-if="record.status == 'in_process'"><b>- En proceso -</b></a-tag>
										<a-tag color="red" v-if="record.status == 'closed'"><b>- Cierre -</b></a-tag>
									</div>
								</div>
								<div slot="action" slot-scope="record">
									<a-tooltip>
										<template slot="title">
											Detalle de registro
										</template>
										<a-button type="info" size="small" icon="edit" @click="onEditRecord(record.id)" />
									</a-tooltip>
									<a-tooltip v-if="userData.role == 'super-admin' || userData.role == 'admin'">
										<template slot="title">
											Eliminar proyecto
										</template>
										<a-button class="ml5" size="small" :icon="record.active ? 'minus-circle' : 'plus-circle'" @click="toggleRecord(record.id)" />
									</a-tooltip>
									<a-tooltip>
										<template slot="title">
											Notificar cierre
										</template>
										<a-button class="ml5" type="info" size="small" icon="mail" @click="generalNotification(record.id)" />
									</a-tooltip>
								</div>
							</a-table>
						</div>
					</div>
				</div>
			</div>
		</a-spin>
	</div>
</template>

<script lang="ts">
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'

export default {
	name: 'projectsView',
	data() {
		return {
			json_data: [],
		}
	},
	computed: {
		...mapGetters('projects', ['projectsList', 'spinnerLoader', 'spinnerLoaderLabel']),
		...mapGetters('auth', ['userData']),
		columns() {
			let columns = [
				{
					title: this.$t('projectsModule.tableHeaders.key'),
					scopedSlots: { customRender: 'code' },
					align: 'center',
					width: '10%',
				},
				{
					title: 'Nombre del Proyecto',
					scopedSlots: { customRender: 'projectName' },
					align: 'center',
					width: '20%',
					ellipsis: true,
				},
				{
					title: 'Nombre del Cliente',
					scopedSlots: { customRender: 'full_name' },
					align: 'center',
					width: '20%',
					ellipsis: true,
				},
				{
					title: "Rentabilidad",
					scopedSlots: { customRender: 'profit' },
					align: 'center',
					width: '10%',
				},
				{
					title: "Presupuesto/Gasto",
					scopedSlots: { customRender: 'budget' },
					align: 'center',
					width: '15%',
				},
				{
					title: "Estatus",
					scopedSlots: { customRender: 'status' },
					align: 'center',
					width: '10%',
				},
				{
					title: this.$t('general.actions'),
					scopedSlots: { customRender: 'action' },
					align: 'right',
					width: '15%',
				},
			]
			return columns
		},
	},
	mounted() {
		this.initModule()
	},
	methods: {
		numeral,
		onDownloadReport() {
			this.json_data = this.projectsList.map(e => {
				return {
					'ID': e.code,
					'Fecha': moment(e.created_at).format('DD-MM-YYYY'),
					'Monto de venta': numeral(e.sale_price).format('0.00'),
					'Monto Costos/Gastos': numeral(e.totalBudget).format('0.00'),
					'Gastos de procesamiento': '',
					'Rentabilidad': numeral(e.profit).format('0.00'),
					'Procesamiento': numeral(e.totalProcessing).format('0.00'),
				}
			})
			setTimeout(() => {
				this.$refs.downloadReportButton.$el.click();
			}, 1000);
		},
		spendBudget(id) {
			let actualRecord = this.projectsList.find(e => e.id == id)
			return Number(numeral(numeral(actualRecord.totalBudget).value() * 100 / numeral(actualRecord.budget).value()).format('0.0'))
		},
		initModule() {
			this.$store.dispatch('projects/GET')
		},
		onReload() {
			this.initModule()
		},
		onAddRecord() {
			this.$router.push('/projects/new')
		},
		onEditRecord(project_id) {
			this.$store.dispatch('projects/GET_ONE', {
				project_id,
			}).then(() => {
				this.$router.push(`/projects/${project_id}`)
			})
		},
		toggleRecord(id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: this.$t('general.deleteMessage.message'),
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					this.$store.dispatch('projects/TOGGLE', id)
				}
			})
		},
		generalNotification(project_id) {
			Swal.fire({
				title: this.$t('general.deleteMessage.title'),
				html: 'Se notificarán vía email a todos los proveedores el cierre del proyecto. ¿Deseas continuar?',
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonText: this.$t('general.yesContinue'),
				cancelButtonText: this.$t('general.cancel'),
			}).then((result) => {
				if (result.isConfirmed) {
					//
					this.$store.dispatch('projects/GENERAL_NOTIFICATION_CLOSED_PROJECT', project_id)
				}
			})
		},
	},
}
</script>
<template>
	<div>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-12">
								<table class="table table-bordered table-condensed table-striped">
									<thead>
										<tr>
											<th class="text-center" colspan="4">
												<h3 class="text-muted pt15">Listado de servicios</h3>
											</th>
										</tr>
										<tr>
											<th class="text-center" width="15%">Cantidad</th>
											<th class="text-center">Descripción</th>
											<th class="text-center" width="15%">Costo unitario</th>
											<th class="text-center" width="15%">Importe</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(element, index) in servicesList" :key="index">
											<td class="text-center">
												{{ element.quantity }}
											</td>
											<td class="text-left">
												{{ element.description }}
											</td>
											<td class="text-right">
												{{ numeral(element.unit_cost).format('$0,0.00') }}
											</td>
											<td class="text-right">{{ numeral(element.unit_cost * element.quantity).format('$0,0.00') }}</td>
										</tr>
										<tr>
											<td class="text-right" colspan="3">Subtotal</td>
											<td class="text-right">{{ numeral(getSubtotal).format('$0,0.00') }}</td>
										</tr>
										<!-- <tr>
											<td class="text-right" colspan="3">I.V.A</td>
											<td class="text-right">{{ numeral(getSubtotal * .16).format('$0,0.00') }}</td>
										</tr>
										<tr>
											<td class="text-right" colspan="3">Total</td>
											<td class="text-right">{{ numeral(getSubtotal * 1.16).format('$0,0.00') }}</td>
										</tr> -->
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div class="col-md-6">
						<div class="row">
							<div class="col-md-12">
								<table class="table table-bordered table-condensed table-striped">
									<thead>
										<tr>
											<th class="text-center" colspan="4">
												<h3 class="text-muted pt15">Listado de facturas</h3>
												<div class="text-center" style="padding-bottom: 5px">
													<a-upload name="file" accept="application/pdf" :showUploadList="false" :fileList="filePDF" :before-upload="handleFileUploadPDF">
														<a-button class="btn" :class="hasPDF ? 'btn-success' : ''"> <a-icon type="file" /> Subir PDF </a-button>
													</a-upload>
													<a-upload name="file" accept="text/xml" :showUploadList="false" :fileList="fileXML" :before-upload="handleFileUpload" v-if="isNationalSupplier">
														<a-button class="btn ml5" :class="hasXML ? 'btn-success' : ''"> <a-icon type="upload" /> Subir XML </a-button>
													</a-upload>
												</div>
												<div v-if="hasPDF || hasXML">
													<a-button @click="uploadFiles"
															  :disabled="isNationalSupplier ? (!hasPDF || !hasXML || supplierSpinnerLoader) : !hasPDF || supplierSpinnerLoader">Procesar
														factura</a-button>
													<a-button class="ml5" @click="clearInvoiceProcess()" :disabled="supplierSpinnerLoader">Cancelar</a-button>
												</div>
											</th>
										</tr>
										<tr>
											<th width="20%" class="text-center">
												Fecha de creación
											</th>
											<th width="10%" class="text-center">
												PDF
											</th>
											<th width="10%" class="text-center" v-if="isNationalSupplier">
												XML
											</th>
											<th width="15%" class="text-center">
												Subtotal
											</th>
										</tr>
									</thead>
									<tbody>
										<tr v-for="(element, index) in supplierFiles" :key="index">
											<td class="text-center">{{ moment(element.pdf.created_at).format('DD-MM-YYYY HH:mm') }} hrs.</td>
											<td class="text-center">
												<a-button @click="getSecureURL(element.pdf.id, 'show')" icon="file" size="small"></a-button>
											</td>
											<td class="text-center" v-if="isNationalSupplier">
												<a-button @click="getSecureURL(element.xml.id, 'download')" icon="download" size="small"></a-button>
											</td>
											<td class="text-right">{{ numeral(element.pdf.subtotal).format('$0,0.00') }}</td>
										</tr>
										<tr>
											<td :colspan="isNationalSupplier ? 3 : 2" class="text-right">Subtotal facturado</td>
											<td class="text-right">{{ numeral(getSubtotalFiles).format('$0,0.00') }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<a-modal width="65%" :visible="modalFileVisible" :footer="null" title="Archivo adjunto" :closable="true" @cancel="handleCancelModalFile" v-if="modalFileVisible">
			<div class="row">
				<div class="col-12 text-center">
					<fileViewerComponent :fileURL="fileURL" />
				</div>
				<div class="col-12 text-right">
					<hr class="hrDotted" />
					<a class="ml5 btn btn-primary" :href="fileURL" target="new">Descargar archivo</a>
				</div>
			</div>
		</a-modal>
	</div>
</template>
<script>
//
import { mapGetters } from 'vuex'
import numeral from 'numeral'
import moment from 'moment'
import xml2js from "xml2js";
import Swal from 'sweetalert2';
import utilities from '@/services/utilities'
import fileViewerComponent from '@/components/fileViewer'
//
import { services } from '@/constants'

export default {
	name: 'projectsDetailView',
	components: {
		fileViewerComponent,
	},
	computed: {
		...mapGetters('projects', ['spinnerLoader', 'spinnerLoaderLabel', 'actualRecord']),
		...mapGetters('auth', ['userData']),
		...mapGetters('taxRegimes', ['taxRegimesList']),
		isNationalSupplier() {
			// console.log('isNationalSupplier--->', this.actualRecord);
			return this.userData.nationality == 'national'
		},
		supplierSpinnerLoader() {
			return this.$store.state.suppliers.spinnerLoader
		},
		supplierSpinnerLoaderLabel() {
			return this.$store.state.suppliers.spinnerLoaderLabel
		},
		getSubtotal() {
			let amount = 0
			if (this.servicesList.length) {
				this.servicesList.map(currentValue => {
					let quantity = numeral(currentValue.quantity).value()
					let unit_cost = numeral(currentValue.unit_cost).value()
					amount += quantity * unit_cost
				})
			}
			return amount
		},
		hasPDF() {
			return !!utilities.objectValidate(this.filePDF, [0, 'uid'], false)
		},
		hasXML() {
			return !!utilities.objectValidate(this.fileXML, [0, 'uid'], false)
		},
		hasFiles() {
			//
			if (utilities.objectValidate(this.supplierData, 'files', false)) {
				return this.supplierData.files.filter(e => e.project_id == this.$route.params.id).length
			}
			return true
		},
		getSubtotalFiles() {
			let total = 0
			this.files.map(e => {
				// console.log('getSubtotalFiles-->', e);
				if (e.mimetype == 'text/xml') {
					total += e.subtotal
				}
			})
			return total
		},
		supplierFiles() {
			if (this.files.length) {
				let newFiles = {}
				for (let index = 0; index < this.files.length; index++) {
					const element = this.files[index];
					let extension = element.key.split('.')[1]

					if (!newFiles[element.file_group]) {
						newFiles[element.file_group] = {
							pdf: {},
							xml: {},
							key: element.file_group,
						}
					}
					newFiles[element.file_group][extension] = element
				}
				return Object.values(newFiles).map(e => e)
			}
			return []
		},
	},
	data: function () {
		return {
			services,
			servicesList: [],
			fileXML: [],
			filePDF: [],
			xmlJson: null,
			supplierData: {},
			totalBilled: 0,
			totalProject: 0,
			files: [],
			fileURL: '',
			modalFileVisible: false,
		}
	},
	mounted() {
		//
		this.getSupplierData()
		this.getSupplierServices()
	},
	methods: {
		numeral,
		moment,
		async getSupplierData() {
			this.supplierData = await this.$store.dispatch('suppliers/GET_ONE', this.userData.id)
		},
		async getSupplierServices() {
			this.$store.dispatch('projects/GET_SUPPLIER_SERVICES', {
				project_id: this.$route.params.id,
				supplier_id: this.userData.id,
			}).then(response => {
				// console.log('response-->', response);
				this.totalBilled = utilities.objectValidate(response, 'totalBilled', 0)
				this.totalProject = utilities.objectValidate(response, 'totalProject', 0)
				this.servicesList = _.cloneDeep(response.servicesList)
				this.files = utilities.objectValidate(response, 'files', [])
			})
		},
		handleFileUploadPDF(file) {
			this.filePDF = [file]
			return false
		},
		handleFileUpload(file) {
			if (file.type !== 'text/xml') {
				Swal.fire({
					title: 'Atención',
					html: 'Solo se permiten archivos XML',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				// Clear input file
				this.fileXML = []
			} else {
				const reader = new FileReader();
				reader.onload = (event) => {
					const xml = event.target.result;
					this.convertXmlToJson(xml);
				};
				reader.readAsText(file);
				this.fileXML = [file]
			}
			return false
		},
		convertXmlToJson(xml) {
			xml2js.parseString(xml, (err, result) => {
				if (err) {
					console.error(err);
					return;
				}
				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante'], false));
				// console.log(utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos'], false));

				// Validamos sobre los impuestos retenidos y el régimen del emisor
				if (utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], false)) {
					let paymentMethod = utilities.objectValidate(result, ['cfdi:Comprobante', '$', 'MetodoPago'], false)
					if (paymentMethod != 'PUE') {
						//
						let taxRegimeCode = utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Emisor', 0, '$', 'RegimenFiscal'], '- No se encuentra el dato -')
						let { code, description } = this.taxRegimesList.find(e => e.code == taxRegimeCode)

						Swal.fire({
							title: 'Validación de XML',
							html: `El XML analizado debe contar con un método de pago dado como PUE debido al régimen que expresa se define como:<br><br>${code} - ${description}`,
							icon: 'error',
							confirmButtonText: 'Ok',
						})

						this.fileXML = []
						return false
					}
				}

				// Validación de los elementos de la factura
				let dataValidation = {
					emisor: {
						Rfc: utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Emisor', 0, '$', 'Rfc'], false),
						RegimenFiscal: utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Emisor', 0, '$', 'RegimenFiscal'], false),
					},
					receptor: {
						Rfc: utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Receptor', 0, '$', 'Rfc'], false),
						RegimenFiscal: utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Receptor', 0, '$', 'RegimenFiscalReceptor'], false),
					},
					conceptos: utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Conceptos', 0, 'cfdi:Concepto'], false) ? result['cfdi:Comprobante']['cfdi:Conceptos'][0]['cfdi:Concepto'].map(e => e['$']) : [],
					subtotal: 0,
					totalImpuestosRetenidos: numeral(utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosRetenidos'], false)).value(),
					totalImpuestosTrasladados: numeral(utilities.objectValidate(result, ['cfdi:Comprobante', 'cfdi:Impuestos', 0, '$', 'TotalImpuestosTrasladados'], false)).value(),
				}

				dataValidation.subtotal = dataValidation.conceptos.reduce((accumulator, currentValue) => accumulator + numeral(currentValue.Importe).value(), 0);

				this.xmlJson = _.cloneDeep(dataValidation);
				// console.log(dataValidation);
			});
		},
		clearInvoiceProcess() {
			this.filePDF = []
			this.fileXML = []
		},
		async uploadFiles() {
			//
			let formData = new FormData()
			let errors = []

			if (this.isNationalSupplier) {
				// validamos si el monto de la factura es menor al restante de la diferencia del total de servicio menos lo facturado
				if (this.getSubtotalFiles + this.xmlJson.subtotal > this.totalProject) {
					errors.push('- La factura excede el total del monto por servicios.')
				}

				// validamos datos de la factura con los del emisor
				if (!utilities.objectValidate(this.supplierData, 'tax.rfc', false) || this.supplierData.tax.rfc != this.xmlJson.emisor.Rfc) {
					errors.push('- El RFC del emisor no concuerda en el registro')
				}

				if (errors.length) {
					Swal.fire({
						title: 'Facturación Proveedores',
						html: errors.join('<br>'),
						icon: 'error',
						confirmButtonText: 'Ok',
					})
					return false
				}

				formData.append('uploadFiles', this.fileXML[0])

				formData.append('subtotal', this.xmlJson.subtotal)
				formData.append('total_impuestos_retenidos', this.xmlJson.totalImpuestosRetenidos)
				formData.append('total_impuestos_trasladados', this.xmlJson.totalImpuestosTrasladados)
			} else {
				await Swal.fire({
					title: 'Ingresa monto total del recibo de pago',
					input: 'text',
					icon: 'info',
					showCancelButton: true,
					reverseButtons: true,
					confirmButtonText: 'Continuar',
					cancelButtonText: 'Cancelar',
					inputValidator: (value) => {
						if (!value) {
							return 'Debes ingresar monto total del recibo de pago'
						}
					},
				}).then((response) => {
					if (response.isConfirmed) {
						formData.append('subtotal', numeral(response.value).value())
						formData.append('total_impuestos_retenidos', 0)
						formData.append('total_impuestos_trasladados', 0)
					}
				})
			}

			formData.append('uploadFiles', this.filePDF[0])
			formData.append('supplier_id', this.supplierData.general.id)
			formData.append('project_id', this.$route.params.id)

			utilities.passwordGenerator().then((group) => {
				formData.append('file_group', group)
			})

			await this.$store.dispatch('suppliers/UPLOAD_FILE', formData).then(response => {
				// limpiamos variables
				this.clearInvoiceProcess()
				this.getSupplierServices()
			})
		},
		getSecureURL(id, action) {
			this.$store
				.dispatch('files/GET_FILE_URL', {
					entity: 'suppliers_files',
					id,
				})
				.then((response) => {
					if (action == 'download') {
						window.open(response.data.url)
					} else {
						this.fileURL = response.data.url
						this.modalFileVisible = true
					}
				})
		},
		handleCancelModalFile() {
			this.fileURL = ''
			this.modalFileVisible = false
		},
	},
}
</script>
